<template>
    <el-col class="main">
        <el-row class="box">
            <el-row class="arow">
                <span>模板名称</span><el-input v-model="templatename"  placeholder="请输入模板名称"></el-input>
            </el-row>
            <el-row class="arow">
                <span>模板编码</span><el-input v-model="templatecode"  placeholder="请输入模板编码" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"></el-input>
            </el-row>
            <el-row class="arow">
                <span>签名</span><el-input  placeholder="请输入内容" v-model="signature" @change="getcontent"></el-input>
            </el-row>
            <el-row class="arow">
                <span>状态</span>
                <el-radio v-model="state" label="1">启用</el-radio>
                <el-radio v-model="state" label="2">关闭</el-radio>
            </el-row>
            <el-row class="arow">
                <span>预置</span>
                <el-radio v-model="preset" label="1">非预置</el-radio>
                <el-radio v-model="preset" label="2">预置</el-radio>
            </el-row>
            <el-row class="arow">
                <span>模板内容</span><el-input type="textarea" placeholder="请输入内容" v-model="content" maxlength="300" show-word-limit></el-input>
            </el-row>
            <el-row>
                <el-button  @click="abrogate">取消</el-button>
                <el-button type="primary"  @click="create()">创建</el-button>
            </el-row>
        </el-row>
    </el-col>
</template>

<script>
import { urlObj } from '@/api/interface'
export default {
    data(){
        return{
            templatename:'',                //模板名称
            content:'',                     //模板内容
            templatecode:'',                //模板编码
            signature:'',                   //签名
            state: '1',                     //状态
            preset:'1',                     //预置
        }
    },
    methods:{
        // 监听输入框
        getcontent(){
            if (this.signature != ''){
                this.content = ''
                this.content = '【'+this.signature+'】'
            }
        },
        create(){
            if (this.templatename == ''){
                this.$message.error('模板名称不能为空！');
                return
            }else if (this.content == ''){
                this.$message.error('模板内容不能为空！');
                return
            }else if (this.templatecode == ''){
                this.$message.error('模板编码不能为空！');
                return
            }else if (this.signature == ''){
                this.$message.error('签名不能为空！');
                return
            }
            let state = ''
            if (this.state == 1){
                state = true
            }else{
                state = false
            }
            let preset = ''
            if (this.preset == 1){
                preset = 'NO'
            }else{
                preset = 'YES'
            }
            const url = urlObj.createAmessage
            const param = {
                content:this.content,
                code:this.templatecode,
                name:this.templatename,
                sign:this.signature,
                state:state,
                isPreset:preset,
            }
            this.$axios.post(url,param).then(res => {
                if (res.status == 200) {
                    this.$message({
                        message: '创建成功!',
                        type: 'success'
                    });
                }
            })
        },

        abrogate(){
            this.$router.push('/temPlates')
        }
    }
}
</script>

<style scoped>
.main{width: 100%; height: 100%; background: white;}
.box{width: 90%; margin: auto; padding-bottom: 1rem;}
.arow{width: 30%; margin: 2rem 0px;}
.arow span{font-size: 14px; display: inline-block;width: 20%;text-align: center}
.arow .el-input{width: 80%}
.arow .el-textarea{width: 80%}
@media screen and (max-width: 1366px) {
  .main {
    width: 1300px;
  }
}
</style>
